<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Pendukung</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="nik_pendukung">NIK : </label>
                  <input
                    v-model="form.nik_pendukung"
                    type="text"
                    class="form-control"
                    name="nik_pendukung"
                    placeholder="NIK"
                    minlength="16"
                    maxlength="16"
                    required
                    :class="{
                      'is-invalid': formValidate.nik_pendukung,
                    }"
                  />
                  <small
                    class="invalid-feedback"
                    v-html="formValidate.nik_pendukung"
                  ></small>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group">
                  <label for="nama_pendukung">Nama: </label>
                  <input
                    v-model="form.nama_pendukung"
                    type="text"
                    class="form-control"
                    placeholder="Nama"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.nama_pendukung,
                    }"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="tgl_lahir_pendukung">Tanggal Lahir: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        @click.prevent="openCalendar"
                      >
                        <i class="fa fa-calendar"></i
                      ></span>
                    </div>
                    <input
                      v-model="form.tgl_lahir_pendukung"
                      @input="calculateAge"
                      type="date"
                      class="form-control"
                      name="tgl_lahir_pendukung"
                      ref="tgl_lahir_pendukung"
                      placeholder="Tanggal Lahir"
                      style="text-transform: uppercase"
                      required
                      :class="{
                        'is-invalid': formValidate.tgl_lahir_pendukung,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="umur_pendukung">Umur : </label>
                  <input
                    v-model="form.usia_pendukung"
                    type="number"
                    readonly
                    class="form-control"
                    name="umur_pendukung"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.usia_pendukung,
                    }"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="agama_pendukung">Agama : </label>
                  <select
                    v-model="form.agama_pendukung"
                    class="form-control"
                    name="agama_pendukung"
                    placeholder="Agama"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.agama_pendukung,
                    }"
                  >
                    <option value="" style="text-transform: uppercase">
                      -Pilih-
                    </option>
                    <option value="Islam">Islam</option>
                    <option value="Katolik">Katolik</option>
                    <option value="Protestan">Protestan</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Budha">Budha</option>
                    <option value="Konghuchu">Konghuchu</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="jenkel_pendukung">Jenis Kelamin : </label>
                  <select
                    v-model="form.jenkel_pendukung"
                    class="form-control"
                    name="jenkel_pendukung"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.jenkel_pendukung,
                    }"
                  >
                    <option value="" style="text-transform: uppercase">
                      -Pilih-
                    </option>
                    <option value="Laki-Laki" style="text-transform: uppercase">
                      Laki-Laki
                    </option>
                    <option value="Perempuan" style="text-transform: uppercase">
                      Perempuan
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="pekerjaan">Pekerjaan : </label>
                  <select
                    v-model="form.pekerjaan"
                    class="form-control"
                    name="pekerjaan"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.pekerjaan,
                    }"
                  >
                    <option value="" style="text-transform: uppercase">
                      -Pilih-
                    </option>
                    <option value="ASN" style="text-transform: uppercase">
                      ASN
                    </option>
                    <option value="Petani" style="text-transform: uppercase">
                      Petani
                    </option>
                    <option value="Nelayan" style="text-transform: uppercase">
                      Nelayan
                    </option>
                    <option value="Peternak" style="text-transform: uppercase">
                      Peternak
                    </option>
                    <option
                      value="Wiraswasta"
                      style="text-transform: uppercase"
                    >
                      Wiraswasta
                    </option>
                    <option value="Honorer" style="text-transform: uppercase">
                      Honorer
                    </option>
                    <option value="Pelajar" style="text-transform: uppercase">
                      Pelajar
                    </option>
                    <option value="Mahasiswa" style="text-transform: uppercase">
                      Mahasiswa
                    </option>
                    <option value="Pensiunan" style="text-transform: uppercase">
                      Pensiunan
                    </option>
                    <option value="Tukang" style="text-transform: uppercase">
                      Tukang
                    </option>
                    <option value="IRT" style="text-transform: uppercase">
                      IRT
                    </option>
                    <option
                      value="Tidak Bekerja"
                      style="text-transform: uppercase"
                    >
                      Tidak Bekerja
                    </option>
                    <option value="Lainnya" style="text-transform: uppercase">
                      Lainnya
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="kab_pendukung">Kabupaten/Kota: </label>
                  <select
                    v-model="form.kab_pendukung"
                    v-on:change="fetchKecamatan()"
                    style="text-transform: uppercase"
                    class="form-control"
                    required
                    :class="{
                      'is-invalid': formValidate.kab_pendukung,
                    }"
                  >
                    <option value="">Pilih Kabupaten/Kota</option>
                    <option
                      v-for="kabupaten in listKabupaten"
                      :value="kabupaten.id_kab"
                      :key="kabupaten.id_kab"
                    >
                      {{ kabupaten.nama_kabupaten }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="kec_pendukung">Kecamatan : </label>
                  <select
                    v-model="form.kec_pendukung"
                    v-on:change="fetchKelurahan()"
                    style="text-transform: uppercase"
                    class="form-control"
                    required
                    :class="{
                      'is-invalid': formValidate.kec_pendukung,
                    }"
                  >
                    <option value="">Pilih Kecamatan</option>
                    <option
                      v-for="kecamatan in listKecamatan"
                      :value="kecamatan.id_kec"
                      :key="kecamatan.id_kec"
                    >
                      {{ kecamatan.nama_kecamatan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="kel_pendukung">Desa/Kelurahan : </label>
                  <select
                    v-model="form.kel_pendukung"
                    style="text-transform: uppercase"
                    class="form-control"
                    required
                    :class="{
                      'is-invalid': formValidate.kel_pendukung,
                    }"
                  >
                    <option value="">Pilih Desa/Kelurahan</option>
                    <option
                      v-for="kelurahan in listKelurahan"
                      :value="kelurahan.id_kel"
                      :key="kelurahan.id_kel"
                    >
                      {{ kelurahan.nama_kelurahan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-1">
                <div class="form-group">
                  <label for="rw_pendukung">RW : </label>
                  <input
                    v-model="form.rw_pendukung"
                    type="text"
                    class="form-control"
                    name="rw_pendukung"
                    placeholder="RW"
                    :class="{
                      'is-invalid': formValidate.rw_pendukung,
                    }"
                  />
                </div>
              </div>

              <div class="col-md-1">
                <div class="form-group">
                  <label for="rt_pendukung">RT : </label>
                  <input
                    v-model="form.rt_pendukung"
                    type="text"
                    class="form-control"
                    name="rt_pendukung"
                    placeholder="RT"
                    :class="{
                      'is-invalid': formValidate.rt_pendukung,
                    }"
                  />
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="tps_pendukung">TPS : </label>
                  <select
                    v-model="form.tps_pendukung"
                    class="form-control"
                    name="tps_pendukung"
                    placeholder="Nomor TPS"
                    required
                    :class="{ 'is-invalid': formValidate.tps_pendukung }"
                  >
                    <option value="" disabled>Pilih Nomor TPS</option>
                    <option
                      v-for="tps in getTpsOptions()"
                      :key="tps"
                      :value="tps"
                    >
                      {{ tps }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="hp_pendukung">Telp/HP : </label>
                  <input
                    v-model="form.hp_pendukung"
                    type="number"
                    class="form-control"
                    name="hp_pendukung"
                    placeholder="Telp/HP"
                    style="text-transform: uppercase"
                    :class="{
                      'is-invalid': formValidate.hp_pendukung,
                    }"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <label>Upload Foto KTP</label>
                <input
                  style="display: none"
                  ref="input"
                  type="file"
                  name="image"
                  accept="image/*"
                  @change="setImage"
                />
                <a
                  href="#"
                  class="btn btn-block btn-primary m-1"
                  role="button"
                  @click.prevent="showFileChooser"
                >
                  <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto KTP
                </a>
                <div class="row">
                  <div class="col-md-12 cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="3 / 2"
                        :src="imgSrc"
                        preview=".preview"
                        style="
                          width: 100%;
                          border-radius: 5px;
                          overflow: hidden;
                        "
                      />
                      <div v-if="this.imgSrc">
                        <div class="btn-group mt-1">
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="rotate(90)"
                          >
                            <i class="fa fa-redo"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="rotate(-90)"
                          >
                            <i class="fa fa-redo fa-flip-horizontal"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="cropImage"
                          >
                            <i class="fa fa-crop"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3" v-if="cropImg">
                <label>Hasil</label>
                <img
                  v-if="cropImg"
                  :src="cropImg"
                  alt="Cropped Image"
                  style="width: 100%; border-radius: 5px; overflow: hidden"
                />
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";
import moment from "moment";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  mixins: [sipData],
  components: {
    VueCropper,
  },
  data() {
    return {
      form: {
        id_pendukung: "",
        nik_pendukung: "",
        nama_pendukung: "",
        tgl_lahir_pendukung: "",
        jenkel_pendukung: "",
        alamat_pendukung: "",
        kab_pendukung: "",
        kec_pendukung: "",
        kel_pendukung: "",
        rt_pendukung: "",
        rw_pendukung: "",
        tps_pendukung: "",
        hp_pendukung: "",
        agama_pendukung: "",
        usia_pendukung: "",
        ktp_pendukung: "",
        pekerjaan: "",
      },
      loading: false,
      showUserAccess: true,
      formValidate: [],
      listKabupaten: [],
      listKecamatan: [],
      listKelurahan: [],
      imgSrc: "",
      cropImg: "",
      notImage: false,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.form.id_pendukung = id;
      this.fetchData();
    } else {
      this.form.id_pendukung = "";
    }
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/pendukung/detail/" + this.form.id_pendukung)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          this.fetchKabupaten();
          if (this.form.kab_pendukung) {
            this.fetchKecamatan();
          }
          if (this.form.kec_pendukung) {
            this.fetchKelurahan();
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();

      if (this.cropImg.includes("http") || this.cropImg == "") {
        // formData.append("foto", "");
      } else {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto", blob, "foto.png");
      }

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/pendukung/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchKabupaten() {
      axios
        .get("/v1/daerah/kabupaten/73", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchKecamatan() {
      axios
        .get("/v1/daerah/kecamatan/" + this.form.kab_pendukung, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchKelurahan() {
      axios
        .get("/v1/daerah/kelurahan/" + this.form.kec_pendukung, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    openCalendar() {
      this.$refs.tgl_lahir_pendukung.focus();
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    calculateAge() {
      if (this.form.tgl_lahir_pendukung) {
        const birthDate = new Date(this.form.tgl_lahir_pendukung);
        const currentDate = new Date();

        const age = currentDate.getFullYear() - birthDate.getFullYear();

        // Check if the birthday has occurred this year
        if (
          currentDate.getMonth() < birthDate.getMonth() ||
          (currentDate.getMonth() === birthDate.getMonth() &&
            currentDate.getDate() < birthDate.getDate())
        ) {
          this.form.usia_pendukung = age - 1;
        } else {
          this.form.usia_pendukung = age;
        }
      }
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.notImage = true;
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.notImage = false;
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.notImage = true;
        alert("Sorry, FileReader API not supported");
      }
    },

    getTpsOptions() {
      return Array.from({ length: 50 }, (_, index) => {
        const tpsNumber = "TPS " + (index + 1).toString().padStart(2, "0");
        return tpsNumber;
      });
    },
  },
};
</script>
